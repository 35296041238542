.exercise-list {
  margin-top: 20px;
  font-size: 14px;

  .exercise-item {
    margin-bottom: 6px;
    background: #295077;
    color: #fff;
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;

    &.exercise-item-edit {
      .exercise-title {
        padding: 10px !important;
      }
    }

    &.exercise-item-in-progress {
      background: #316AA3;
      border-radius: 6px;
      align-items: inherit;

      & + .exercise-item {
        border: 0;
      }

      .exercise-set-data:not(.active-set) {
        color: #ffffff88;
      }

      .exercise-title {
        font-weight: 600;
        padding-right: 84px !important;

        a {
          right: 96px
        }
      }

      .exercise-set-number {
        flex-basis: 12% !important;
      }
      .exercise-status {
        flex-basis: 16% !important;
      }
      .exercise-set-titles, .exercise-set-pairs {
        flex-basis: 72% !important;

        &.exercise-superset-pairs {
          flex-basis: 36% !important;
        }
      }
    }

    .exercise-sort-buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &.exercise-item-completed {
      .exercise-set-data {
        color: #ffffff88;
      }
    }

    &.exercise-item-completed-view {
      .exercise-title {
        padding-right: 10px !important;
      }
    }

    &.exercise-item-open {
      flex-direction: column;
      align-items: inherit;

      .exercise-title {
        padding-right: 50px;
      }
    }

    &.exercise-item-complete {
      //text-decoration: line-through;
    }

    &.exercise-item-superset {
      .exercise-title {
        padding-right: 70px !important;
        flex-wrap: wrap;
      }

      &.exercise-item-in-progress  .exercise-title,
      &.exercise-item-open .exercise-title {
        padding-right: 10px !important;
      }
    }

    .exercise-title {
      font-size: 16px;
      line-height: 18px;
      position: relative;
      padding: 10px 36px 10px 10px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      small {
        display: block;
        color: #ffffff88;
        font-size: 12px;
        font-weight: normal;
        margin-top: 4px;
      }

      .exercise-title-data {
        padding-left: 8px;
        color: #ffffff88;
        font-size: 12px;
        line-height: 1;
      }

      .exercise-title-links {
        display: flex;
        align-items: center;
        height: 34px;
        margin-right: -5px;
        margin-top: -7px;
        margin-left: 6px;

        a {
          color: inherit;
          text-decoration: none;
          box-sizing: border-box;
        }

        & > * {
          margin-left: 6px;
        }
      }

      .exercise-title-edit {
        border-bottom: #ffffff88 1px dashed;
      }
    }

    .exercise-buttons {
      position: absolute;
      right: 0;
      display: flex;
      justify-content: flex-end;
      align-self: flex-start;

      .exercise-countdown {
        position: absolute;
        right: 0;
        top: 0;
        background: #295077;
        padding: 2px 10px;
        border-radius: 0 0 0 6px;

        &.exercise-time {
          background: #4E7EB0;
        }
      }
    }

    .exercise-data {
      flex-basis: 30%;
      padding: 0 20px 20px;

      & + .exercise-item-comments {
        margin-top: -12px;
      }
    }

    .exercise-set-data {
      display: flex;
      position: relative;
      padding: 4px 0;

      &.exercise-set-complete {
        color: #ffffff88;
      }

      .set-checkbox {
        margin: -6px;
      }

      &.active-set {
        font-weight: 600;
        background: #4E7EB0;
        border-radius: 26px;
        padding: 4px 12px;
        margin: 4px -12px;

        .exercise-set-pairs {
          font-size: 24px;
          line-height: 30px;
        }

        .exercise-set-number {
          font-size: 20px;
        }

        .set-checkbox {
          margin: -12px;

          svg {
            width: 32px;
            height: 32px;
            fill: #ffffffff;
          }

          &.Mui-disabled svg {
            opacity: 0.6;
          }
        }
      }

      .exercise-timer {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        border-radius: 50%;
        background: #4e7eb0;
      }
    }

    .exercise-item-comments {
      font-style: italic;
      font-size: 90%;
      padding: 0 10px 10px;
      color: #ffffffaa;
    }

    .setList-superset-title {
      text-align: center;
      max-width: 45%;
      margin: 0 auto;
      flex: 1;
      font-size: 85%;
    }

    .exercise-set-header {
      display: flex;
      font-size: 12px;
      color: #ffffff88;

      .exercise-set-number {
        flex-basis: 10%;
      }

      .exercise-set-titles {
        height: 20px;
        display: flex;
        flex-basis: 80%;
        //flex: 1;

        .exercise-set-titles-item {
          text-align: center;
          flex: 1;
        }
      }

      .exercise-status {
        text-align: right;
        flex-basis: 10%;
      }
    }

    .exercise-set-number {
      flex-basis: 10%;
      align-self: center;
      padding-left: 4px;
    }

    .exercise-set-plus {
      position: relative;
      color: #00000088;
      font-weight: 600;
      //flex-basis: 6%;
      //text-align: center;
      //align-self: center;
    }

    .exercise-set-pairs {
      display: flex;
      flex-basis: 80%;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      justify-content: center;

      &.exercise-superset-pairs {
        flex-basis: 40%;
      }

      .exercise-set-reps {
        position: relative;
      }

      .exercise-set-value {
        flex: 1;
        text-align: center;
        position: relative;

        small {
          position: absolute;
          bottom: 0px;
          right: -4px;
          font-weight: normal;
        }
      }

      .set-default-value {
        font-size: 80%;
        position: absolute;
        //left: 50%;
        margin-left: 4px;
        margin-top: 2px;
      }

      .set-result-value {
        //color: #aa0000;
      }

      .exercise-set-edit-item {
        margin: 0 10px;
        //width: 42%;
      }
    }

    .exercise-status {
      flex-basis: 10%;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
    }

    .exercise-bottom-buttons {
      display: flex;
      justify-content: space-between;
      padding: 6px 10px 16px;
    }

    .record-video-button {
      position: fixed;
      top: 9px;
      right: 56px;
      z-index: 11;
    }
  }

  .exercise-list-warmup {
    margin-bottom: 8px;
    padding-bottom: 2px;
    border-bottom: 1px solid #ffffff33;
  }

  .exercise-list-cardio {
    margin-top: 8px;
    padding: 8px 0;
    border-top: 1px solid #ffffff33;
    border-bottom: 1px solid #ffffff33;
  }

  .exercise-list-item {

  }

  .exercise-edit-list-item {
    &.exercise-superset {
      .exercise-item {
        border-radius: 6px 6px 0 0;
      }

      & + .exercise-supersetPair {
        .exercise-item {
          margin-top: -7px;
          padding-top: 33px;
          border-radius: 0 0 6px 6px;
        }
      }

      .exercise-edit-buttons-wrapper {
        padding: 0;
      }
    }

    .exercise-edit-buttons-wrapper {
      position: relative;
      margin-bottom: 2px;
      padding: 4px 0;
    }
  }
}


.exercises-list {
  ul {
    padding: 0;
  }
}

.exercises-search-dialog-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  .exercises-list {
    margin-top: 16px;
    width: 100%;
    position: relative;
    overflow: auto;
    border: 1px solid #FFFFFF44;
    border-radius: 4px;
    padding-bottom: 0;
  }
}

.exercises-page {
  position: relative;

  .exercises-list {
    ul {
      margin-top: 8px;
      border: 1px solid #295077;
      border-radius: 6px;
      overflow: hidden;
    }

    .exercises-list-subheader {
      font-size: 100%;
      color: #fff;
      background: #295077;
      //border-radius: 6px 6px 0 0;
    }
  }
}

.exercise-video-record-buttons {
  background: white;
  padding: 0;
  margin: 10px;
  border-radius: 24px;

  .exercise-video-record-start {
    padding: 0;
  }

  .exercise-video-record-stop {
    padding: 0;
    width: 48px;
    height: 48px
  }
}

.exercise-timer {
  color: #ffffff;
  height: 38px;
  width: 38px;
  padding: 4px;

  .exercise-timer-text {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 38px;
    line-height: 38px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
