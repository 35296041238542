.profile-section {
  margin-top: 32px;

  .profile-section-wrapper {
    padding: 12px;
    border: 1px solid #ffffff33;
    border-radius: 8px;
    background: #295077;
  }

  .profile-section-edit-button {
    margin: -4px;
  }

  &.profile-section-edit {
    background: #295077;
  }
}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-section-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.profile-section-item {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-section-edit-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.user-profile-page {
  .user-stats {
    .user-stats-item {
      color: #ffffffaa;
      font-size: 85%;
      display: flex;
      align-items: center;

      .user-stats-item-title {
        margin: 0 4px;
        flex-basis: 35%;
      }

      & + .user-stats-item {
        margin-top: 8px;
      }

      b {
        color: #ffffffcc;
      }
    }
  }
}