.app {
  background: #0F1F32;
  color: #ffffff;
  min-height: 100%;
}

.login {
  background: #0f1f32;
  color: #ffffff;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding-top: 30vh;
  margin: 0 auto;

  .role-button {
    position: relative;
    margin: 20px;
  }
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: #ffffff22;
  text-align: center;

  a {
    color: inherit;
    text-decoration: none;
  }

  .workout-timer {
    justify-content: center;
  }
}

.top-loader {
  position: fixed;
  top: 0;
  z-index: 3;
  left: 0;
  width: 100%;
}