.workouts-page, .user-profile-page {
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .workouts-page-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 16px;
  }

  .workouts-list-item {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    &.workouts-list-item-today, &.workouts-list-item-active-client {
      background: #295077;
    }

    &.workouts-list-item-hidden {
      display: none;
    }

    &.workouts-list-item-active {
      background: #f44336;

      .workouts-list-item-left {
        display: flex;
        flex-basis: 100% !important;
        justify-content: space-between;
      }
    }

    a {
      text-decoration: none;
    }

    .workouts-list-item-left {
      flex-basis: 90%;
      padding: 10px;
    }

    .workouts-list-item-rating {
      display: flex;
      height: 16px;
      //margin-right: 4px;

      svg {
        width: 16px;
        height: 16px
      }
    }

    .workouts-list-item-button-start {
      align-self: center;
      flex-basis: 50%;
      font-size: 70%;
    }

    .workouts-list-item-title {
      color: #ffffffee;
      font-weight: 600;
      margin-bottom: 4px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
      }
    }

    .workouts-list-item-date {
      color: #ffffff88;
      font-size: 80%;
    }
  }

  .workout-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .workout-date {
    //text-transform: uppercase;
    font-size: 18px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  }

  .workout-date-edit {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    border-bottom: #ffffff77 1px dashed;
    min-height: 26px;

    svg {
      margin-left: 4px;
    }
  }

  .workout-date-edit-datepicker {
    position: absolute;
    left: 0;
    top: 0;

    &.hidden {
      opacity: 0;
    }
  }
}

.workouts-calendar {
  .workouts-calendar-completed {
    & > button {
      border: 2px solid #ffa726;
    }
  }

  .workouts-calendar-today {
    & > button {
      border: 2px solid darkgreen;
    }
  }

  .workouts-calendar-upcoming {
    & > button {
      border: 2px solid darkgreen;
    }
  }
}

.workout-bottom-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  &.bottom-fixed-buttons {
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 20px;
    background: #0f1f32;
    //box-shadow: #0f1f32 0 -2px 10px;
  }
}

.workout-timer {
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }
}

.client-workouts-list {
  .workouts-list-item-left {
    display: flex;
    align-items: baseline;
    font-size: 14px;
    flex-basis: 100% !important;
    justify-content: space-between;

    .workouts-list-item-title {
      margin-bottom: 0;
      display: flex;
      align-items: baseline;

      svg {
        position: relative;
        top: 3px;
      }
    }

    & + div {
      display: none;
    }

    .workouts-list-item-date {
      margin-left: 8px;
    }
  }
}