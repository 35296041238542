.page {
  padding: 16px;
  max-width: 600px;
  margin: 0 auto;

  header {
    margin-bottom: 20px;
  }

  &.workout-page {
    padding-top: 0;
  }

  &.user-profile-page {
    //padding-bottom: 60px;
  }

  &.clients-page {
    padding: 0;
    margin-top: -8px;
  }
}


.page-buttons {
  display: flex;
  justify-content: space-between;
}

.home-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  &.home-button-active {
    border: 2px solid red;
  }
}

.edit-workout-page {
  .workout-header {
    margin-bottom: 36px;
  }
}

.clients-page {
  .clients-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    padding: 16px;

    .clients-list-item-progress {
      margin-left: auto;
      width: 20px;
      height: 20px;
      animation: colorChange 2s ease-in-out infinite;
    }
  }
}

@keyframes colorChange {
  0%, 100% {
    color: red;
  }
  50% {
    color: white;
  }
}